* {
  margin: 0;
  padding: 0;
}

body {
  background: black;
}

iframe {
  display: none;
}

.container {
  width: 100%;
  height: 100%;
}

.circle {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 1s, left 1s;
  z-index: -1;
}

.gunes {
  width: 600px;
  height: 600px;
  background-color: #dd4418;
  border-radius: 50%;
  position: relative;
  margin: auto;
  filter: blur(100px);
  margin-top: -200px;
  overflow: hidden;
  z-index: -1;
}

.gunes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #d83f16 33.33%,
    #ce3529 50%,
    #b7242e 66.67%,
    #a91f40 73.33%,
    #490b7f 10%,
    #270363 20%,
    #270363
  );
  border-radius: 50%;
}
.cricle {
  width: 200px;
  height: 200px;
  float: right;
  margin-right: 150px;
  margin-top: -180px;
}
.banner {
  color: aliceblue;
  margin-top: -380px;
  width: 100%;
  display: flex;
  height: 20vh;
}
.rightBanner {
  margin-left: auto;
  padding: 10px;

  color: white;
}
.leftBanner {
  margin-right: auto;
  display: flex;
  margin: 10px;
}
#logo {
  width: 100px;
  height: 100px;
  margin: 5px;
}

.bannerYazi {
  margin: 5px;
  width: 55vh;
}
.bannerYazi .bannerYaziUst {
  font-size: 500%;
  font-family: "Copernicus", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: center;
}
.bannerYazi .bannerYaziAlt {
  text-align: center;
  font-size: 110%;
  font-family: "Proxima Nova", "Times New Roman", Times, serif;
}
.rightBanner .loginSign {
  width: 40vh;
  margin-top: -5px;
  display: flex;
}
.loginYazi {
  margin-right: auto;
  font-size: 4vh;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.signYazi {
  margin-right: auto;
  font-size: 4vh;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.rightBanner .icon {
  display: flex;
  justify-content: space-between;
  width: 30vh;
  margin-top: 30px;
}
.icon i {
  color: white;
  margin: 0 5px;
  /* İstediğiniz boşluğu ayarlayabilirsiniz */
}
.ortaBilgi {
  color: #d83f16;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 400px;
  border-radius: 2px;
  box-shadow: 0px 4px 8px rgba(255, 165, 0, 0.5);

  border-style: solid;
  border-width: 1px;
  border-color: #270363;
  margin-left: auto;
  margin-right: auto;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 70px;
  font-size: 3.2vh;
  font-weight: bold;
}

.ortaBilgi .kullaniciAdiBilgi {
  display: flex;
  flex-direction: column;
}

.kullaniciAdiText {
  margin: 10px;
  width: 95%;
  height: 35px;
  color: white;

  border-style: none;
  margin-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-color: #dd4418;
  padding-left: 5px;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  background-color: transparent; /* Arka plan rengi transparan (renksiz) */
}

.girisAciklama {
  text-align: center;
  color: white;
  font-size: 4vh;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.sifreText {
  margin: 10px;
  width: 95%;
  color: white;
  height: 35px;
  margin-bottom: 20px;
  border-style: none;
  border-bottom-style: solid;
  border-bottom-color: #dd4418;
  padding-left: 5px;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  background-color: transparent; /* Arka plan rengi transparan (renksiz) */
}

.username {
  color: white;
  font-size: 2vh;
  margin-left: 10px;
  margin-bottom: -10px;
}
.loginButton {
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: linear-gradient(to top, #ff2822, #fb8c00);
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 8px;
  border-style: none;
  border-radius: 5px;
  font-size: 2vh;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1vh;
  }

  .gunes {
    width: 100%;
    max-width: 400px;
  }

  .cricle {
    width: 30%;
    max-width: 100px;
    float: none;
    margin: 0 auto;
    float: right;
    margin-top: -110px;
    margin-right: 10px;
  }

  .banner {
    height: auto;
    display: block;
  }

  .rightBanner,
  .leftBanner {
    margin: 5px;
    flex-basis: 100%;
    text-align: center;
  }

  .rightBanner {
    margin-top: 20px;
  }

  .bannerYazi {
    width: 100%;
  }

  .rightBanner .loginSign {
    width: 100%;
    margin-top: 10px;
  }

  .loginYazi,
  .signYazi {
    font-size: 3vh;
  }

  .rightBanner .icon {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .ortaButton {
    margin-top: 20px;
    width: 60%;
  }
}

.buton {
  background-color: transparent; /* Arka plan rengi */
  border: none; /* Kenarlık yok */
  color: white; /* Yazı rengi */
  padding: 10px 20px; /* İç boşluklar */
  text-align: center; /* Metin ortalanmış */
  text-decoration: none; /* Alt çizgi yok */
  display: inline-block;
  font-size: 25px;
  font-family: "Copernicus", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  cursor: pointer; /* Fare imlecini el işareti yap */
}

.buton:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Yarı saydam beyaz arka plan rengi */
}

/* CONTAINER */
ul {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}

li {
  margin: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button-perspective {
  z-index: 2;
  top: 30px;
  padding: 10px;
  width: 450px;
  height: 90px;
  background-color: #f8c954;
  border: 4px solid black;
  border-radius: 50px;
  box-sizing: border-box;
  text-transform: uppercase;
  color: white;
  display: flex;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  justify-content: center;
  line-height: 1.75rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  position: relative;
  cursor: text;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-perspective:focus {
  outline: 0;
}

.button-perspective::placeholder {
  color: white;
}

.button-perspective:after {
  content: "";
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-perspective:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-perspective {
    padding: 0.75rem 3rem;
    font-size: 1.25rem;
  }
}

.button-perspective2 {
  z-index: 1;
  top: -32px;
  padding: 10px;
  width: 450px;
  height: 80px;
  background-color: #ff5c00;
  border: 4px solid black;
  border-radius: 50px;
  box-sizing: border-box;
  color: transparent;
  display: flex;
  font-size: 0;
  font-weight: 700;
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  position: relative;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-perspective2:focus {
  outline: 0;
}

.button-perspective2::placeholder {
  color: transparent;
}

.button-perspective2:after {
  content: "";
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-perspective2:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-perspective2 {
    padding: 0.75rem 3rem;
  }
}

.textbox1 {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  border: 4px solid white;
  border-radius: 10px;
  padding: 10px;
  width: 500px;
  height: 65px;
  z-index: 1; /* Ekleme */
}

.textbox1 input[type="text"] {
  text-align: center;
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 5px;
  height: 100%;
  width: 100%; /* İçerik alanının genişliği, textbox1 ile aynı olacak şekilde ayarlandı */
  font-size: 20px; /* Yazı boyutunu ayarlayabilirsiniz */
  resize: none; /* Metin kutusunun yeniden boyutlandırılmasını devre dışı bırakır */
  outline: none; /* Metin kutusu odaklandığında çerçevenin görünmemesini sağlar */
  text-transform: uppercase;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background: black;
}
.container {
  width: 100%;
  height: 100%;
}
.gunes {
  width: 600px;
  height: 600px;
  background-color: #dd4418;
  border-radius: 50%;
  position: relative;
  margin: auto;
  filter: blur(100px);
  margin-top: -200px;
  overflow: hidden;
  z-index: -1;
}

.gunes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #d83f16 33.33%,
    #ce3529 50%,
    #b7242e 66.67%,
    #a91f40 73.33%,
    #490b7f 10%,
    #270363 20%,
    #270363
  );
  border-radius: 50%;
}
.circle {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 1s, left 1s;
  z-index: -1;
}
.banner {
  color: aliceblue;
  margin-top: -380px;
  width: 100%;
  display: flex;
  height: 20vh;
}
.rightBanner {
  margin-left: auto;
  padding: 10px;

  color: white;
}
.leftBanner {
  margin-right: auto;
  display: flex;
  margin: 10px;
}
#logo {
  width: 100px;
  height: 100px;
  margin: 5px;
}

.bannerYazi {
  margin: 5px;
  width: 55vh;
}
.bannerYazi .bannerYaziUst {
  font-size: 500%;
  font-family: "Copernicus", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: center;
}
.bannerYazi .bannerYaziUst1 {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  font-size: 25px;
  font-family: "Copernicus", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: center;
}
.bannerYazi .bannerYaziUst2 {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  font-size: 25px;
  font-family: "Copernicus", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: center;
}

.bannerYazi .bannerYaziUst3 {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  font-size: 25px;
  font-family: "Copernicus", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-align: center;
}
.bannerYazi .bannerYaziAlt {
  text-align: center;
  font-size: 110%;
  font-family: "Proxima Nova", "Times New Roman", Times, serif;
}
.rightBanner .loginSign {
  width: 40vh;
  margin-top: -5px;
  display: flex;
}
.loginYazi {
  margin-right: auto;
  font-size: 4vh;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.signYazi {
  margin-right: auto;
  font-size: 4vh;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.rightBanner .icon {
  display: flex;
  justify-content: space-between;
  width: 30vh;
  margin-top: 30px;
  margin-left: 40px;
}
.icon i {
  margin: 0 5px;
  /* İstediğiniz boşluğu ayarlayabilirsiniz */
}
.ortaButton {
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 300px;
  height: 80px;
  background: linear-gradient(to top, #ff2822, #fb8c00);
  margin-left: auto;
  margin-right: auto;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 8px;
  border-radius: 25px;
  font-size: 3.5vh;
  font-weight: bold;
}
.altt {
  color: white;
  width: 97%;
  height: 80px;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  font-family: "Copernicus", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 3.5vh;
  font-weight: bold;

  justify-content: center;
  align-items: center;
  margin: 20px;
  justify-content: space-between;
  margin-top: 80px;
}
.alttBaslik {
  border-bottom-style: solid;
  border-bottom-color: #ff2822;
  border-bottom-width: 1px;
}
.alttTuruncu {
  width: 200px;
  height: 10px;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  background-color: #fb8c00;
  margin-top: 10px;
}
.imgs {
  display: flex;
  justify-content: space-between;
}
#altimg {
  width: 250px;
  height: 200px;
  position: fixed;
  left: 50%;
  bottom: 3%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1vh;
  }

  .gunes {
    width: 100%;
    max-width: 400px;
  }

  .cricle {
    width: 30%;
    max-width: 100px;
    float: none;
    margin: 0 auto;
    float: right;
    margin-top: -110px;
    margin-right: 10px;
  }

  .banner {
    height: auto;
    display: block;
  }

  .rightBanner,
  .leftBanner {
    margin: 5px;
    flex-basis: 100%;
    text-align: center;
  }

  .rightBanner {
    margin-top: 20px;
  }

  .bannerYazi {
    width: 100%;
  }

  .rightBanner .loginSign {
    width: 100%;
    margin-top: 10px;
  }

  .loginYazi,
  .signYazi {
    font-size: 3vh;
  }

  .rightBanner .icon {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .ortaButton {
    margin-top: 20px;
    width: 60%;
  }

  .altt {
    margin-top: 20px;
  }

  .imgs {
    flex-direction: column;
  }

  #altimg {
    width: 100%;
  }
}
